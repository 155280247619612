<template>
<div>
  <div class="page-container">
    <div class="content-wrap">
      <div class="inner">
        <div class="pt3 tc">
            <img src="/assets/reah-li.jpg" class="br-100 h4 w4 dib" alt="avatar">
          </div>
        <div class="center tc h-50 f1 reah pa1">
          
          <span class="reah">reah</span><span class="fw2 miyara mid-gray">miyara</span>
        </div>
        <div class="center f5-ns f6 fw4 w-80-ns w-90 tc tagline pt2 ph2 dark-gray">AI product leader with a decade of impactful contributions at <a class="blue link" href="https://www.jpl.nasa.gov/" target="_">NASA JPL</a>, <a class="blue link" href="https://www.ibm.com/watson" target="_">IBM Watson</a>, <a class="blue link" href="https://ai.google.com" target="_">Google AI</a>, & startups.</div>
        <div class="center f5-ns f6 fw4 w-80-ns w-100 tc tagline pt2 ph2 dark-gray"><!--<span class="i f7">present:--></span> <span class="fw5 ">Product <a class="blue link" href="https://openai.com" target="_">@OpenAI</a>, Advisor <a class="blue link" href="/inquiry" target="_blank">@Reah.AI</a></span></div>
        <!-- <div class="w-100 tc center pic-container pt0-ns pt3"><img onDragStart="return false" alt="Man vs Machine" onContextMenu="return false" class="image w-100 h-80 fl" src="/assets/Man-vs-Machine-small.png"/></div> -->

        <div class="w-80-ns w-100 center f3-ns tc f4 wrapper pt4 mt5-ns">
          <!-- <hr/> -->
          <!-- Blog link begin  -->
          <!-- <a class="pa3 ph4-l dib tc button w-20-ns w-100 black link" target="" href="https://reah.me/blog">blog</a> -->
          <!-- Blog link end  -->
          <!-- Calendly link widget begin -->
          <router-link class="pa3 ph4-l dib tc button w-15-ns black link bl-ns br-ns" target="_blank" to="/coffee">coffee ☕</router-link>
          <!-- Calendly link widget end -->
          <a v-if="isMobile()" class="pa3 ph4-l dib tc button w-15-ns black link br-ns" target="_blank" href="mailto:cv@reah.ai?subject=Resume%20request&body=Hi%20Reah,%0A%0AI%20would%20like%20to%20receive%20a%20copy%20of%20your%20resume.%0A%0AThanks,%0A<Name>%0A<Phone>%0A<Email>">cv 📝</a>
          <a v-else class="pa3 ph4-l dib tc button w-15-ns black link br-ns" target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=cv@reah.ai&su=Resume%20request&body=Hi%20Reah,%0A%0AI%20would%20like%20to%20receive%20a%20copy%20of%20your%20resume.%0A%0AThanks,%0A<Name>%0A<Phone>%0A<Email>">cv 📝</a>
          <a class="pa3 ph4-l dib tc button w-15-ns black link br-ns" target="_blank" href="https://www.github.com/reah">github 💻</a>
          <a class="pa3 ph4-l dib tc button w-15-ns black link br-ns" target="_blank" href="https://www.linkedin.com/pub/reah-miyara/78/57/926">linkedin 🤝</a>
          <a class="pa3 ph4-l dib tc button w-15-ns black link br-ns" target="_blank" href="https://www.strava.com/athletes/reah_">strava 🚵‍♂️</a>
          <router-link to='/contact' class="pa3 ph4-l dib tc button w-15-ns black link br-ns">contact ✉️</router-link>


          <!-- <hr/> -->
        </div>

      </div>
    </div>
    <div class="w-100 tc f7 fw3 footer pv2-ns pv1">site by me (reah) 🧿</div>
  </div>
</div>
</template>

<script>
  import JSON from 'JSON'
  import axios from 'axios'

  export default {
    name: 'Home',
    data: function() {
      return {
        sharedState: this.$root.sharedState
      }
    },
    mounted: function() {
      this.parseURL()
    },
    methods: {
      validURL: function (str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      },
      parseURL: function() {
        let query = this.$route.query;
        let shortname = this.$route.fullPath.split('=')[0].replace('/', '').replace('?','')
        let url = query[shortname]
        // console.log('QUERY: ', query, shortname, url)

        // IF query param is a shortname and respective url
        if(Object.keys(query).length >= 1 && query[shortname] !== null) {
          let data = {
            short: shortname,
            url: url
          }
          console.log('adding url shortname and link', data)
          axios.post('https://reah.ai/url', data)
          .then(function(res) {
            console.log(res)
          })
        }
      },
      isMobile: function() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      }
    }
}
</script>
<style scoped>
.page-container {
  position: relative;
  min-height: 90vh;
}

hr {
  color: black;
  margin: 0px;
  padding: 0px;
}

.content-wrap {
  padding-bottom: 2.5rem;    /* Footer height */
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;            /* Footer height */
}

.button:hover {
  cursor: pointer;
  font-weight: 300;
}
.w-15-ns {
    width: 16%;
  }

.br-ns {
  border-right-style: solid; border-right-width: 1px; 
}
.bl-ns {
  border-left-style: solid; border-right-width: 1px; 
}
@media(max-width: 1200px) {
  .w-15-ns {
    width: 100% !important;
  }
  .br-ns{
    border-style: none !important;
  }
  .bl-ns{
    border-style: none !important;
  }
  .page-container {
    position: relative;
    min-height: 80vh;
  }
}
</style>
